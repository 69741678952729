import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { allKey, listTablePrefs } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import useReactRouter from 'use-react-router'
import { listCapiClusters } from './capi/actions'
import { listClusters } from './newActions'
import { QbertAndCapiCombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { useAppSelector } from 'app/store'
import { capiClustersSelector } from './capi/selectors'
import { clustersSelector } from './selectors'
import { qbertAndCapiClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { both, pick, uniq } from 'ramda'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import AddClusterButton from './AddClusterButton'
import ClusterNameCell from './cluster-cells/ClusterNameCell'
import ClusterTypeCell, { formattedClusterTypes } from './cluster-cells/ClusterTypeCell'
import InfrastructureTypeCell, {
  getInfrastructureType,
} from './cluster-cells/InfrastructureTypeCell'
import K8sVersionCell from './cluster-cells/K8sVersionCell'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import StatsCell from '../common/cells/StatsCell'
import UUIDCell from '../common/cells/UUIDCell'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import {
  canDeleteCapiCluster,
  canDeleteCluster,
  canForceScaleMasters,
  canForceScaleWorkers,
  canScaleMasters,
  canScaleWorkers,
  canUpgradeCapiCluster,
  canUpgradeCluster,
  clusterIsBeingDeleted,
  getClusterStatus,
  isCapiCluster,
  isQbertCluster,
} from './helpers'
import { ClusterTypes } from './model'
import { capiClusterIsHealthy, qbertClusterIsHealthy } from './ClustersOverviewListPage'
import StringMultiDropdownFilter from 'core/elements/grid/filters/StringMultiDropdownFilter'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { isAdmin } from '../common/helpers'
import CapiClusterDeleteDialog from './capi/CapiClusterDeleteDialog'
import ClusterDeleteDialog from './ClusterDeleteDialog'
import { routes } from 'core/utils/routes'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import ForceScaleClusterDialog from './ForceScaleClusterDialog'
import PollingData from 'core/components/PollingData'
import ClusterLinkCell from './cluster-cells/ClusterLinkCell'
import { createCidrRangesCell } from './cluster-cells/CidrRangesCell'
import getClusterBooleanCell from './cluster-cells/getClusterBooleanCell'
import BulkClusterDeleteDialog from './BulkClusterDeleteDialog'
import { listCapiHosts } from './capi/details/overview/hosts/actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { capiHostsSelector } from './capi/details/overview/hosts/selectors'
import { listK8sNodes } from '../nodes/new-actions'
import { k8sNodesSelector } from '../nodes/k8sNodes-selectors'
import UpgradeClusterPage from './UpgradeClusterPage'
import ScaleMastersPage from './ScaleMastersPage'
import ScaleWorkersPage from './ScaleWorkersPage'
import DataKeys from 'k8s/DataKeys'
import { listClusterAddons } from './cluster-addons/new-actions'

type ModelDataKey = DataKeys.Clusters | DataKeys.CapiClusters
type SelectorModel = ArrayElement<ReturnType<typeof qbertAndCapiClustersSelector>>

const usePrefParams = createUsePrefParamsHook('Managed Clusters', listTablePrefs)

const defaultParams = {}
const searchTargets = ['name', 'uuid']
const oneSecond = 1000

const clusterHealthOptions = [
  { label: 'Healthy', value: 'healthy' },
  { label: 'Unhealthy', value: 'unhealthy' },
]

const checkAllDeletable = (clusters) => {
  return clusters.every((cluster) => {
    return isCapiCluster(cluster) ? canDeleteCapiCluster([cluster]) : canDeleteCluster([cluster])
  })
}

const getManagedClusterHealthStatus = (cluster): StatusCellModel => {
  let healthy = null
  let loading = null
  let converging = null

  if (clusterIsBeingDeleted(cluster)) {
    return { variant: 'error', label: 'Deleting' }
  }

  if (isQbertCluster(cluster)) {
    healthy = qbertClusterIsHealthy(cluster)
    if (cluster.status === 'pending') {
      converging = true
    }
    loading = getClusterStatus(cluster?.nodes)?.label === 'Loading...'
  } else {
    healthy = capiClusterIsHealthy(cluster)
    // Doesn't seem like capi has a loading issue like with qbert clusters
    loading = false
  }

  return healthy
    ? { variant: 'success', label: 'Healthy' }
    : converging
    ? { variant: 'unknown', label: 'Converging' }
    : loading
    ? { variant: 'unknown', label: 'Loading...' }
    : { variant: 'error', label: 'Unhealthy' }
}

const getManagedClusterAddonsStatus = (cluster) => {
  return cluster.addonsHealthy
    ? { icon: null, iconTooltip: null }
    : { icon: 'exclamation-triangle', iconTooltip: 'Some cluster add-ons are unhealthy' }
}

export default function ManagedClustersListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { history } = useReactRouter()

  // const { loading: loadingCapiClusters, reload: reloadCapiClusters } = useListAction(
  //   listCapiClusters,
  //   {
  //     params,
  //   },
  // )
  const { loading: loadingLegacyClusters, reload: reloadLegacyClusters } = useListAction(
    listClusters,
    {
      params,
    },
  )
  const { loading: loadingK8sNodes, reload: reloadK8sNodes } = useListAction(listK8sNodes)
  const { loading: loadingClusterAddons } = useListAction(listClusterAddons, {
    params: { clusterId: allKey },
  })

  const managedClusters = useAppSelector(qbertAndCapiClustersSelector)
  const k8sNodes = useSelectorWithParams(k8sNodesSelector, params)

  // Not putting loadingK8sNodes here gives a much better user experience since K8sNodes take some time to load
  const loading = loadingLegacyClusters

  const handleReload = useCallback(async (refetch = false, updateLoadingState = true) => {
    // reloadCapiClusters(refetch, updateLoadingState)
    reloadLegacyClusters(refetch, updateLoadingState)
    reloadK8sNodes(refetch, updateLoadingState)
  }, [])

  useEffect(() => {
    reloadK8sNodes(true, true)
  }, [])

  const [selectedCluster, setSelectedCluster] = useState(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [selectedClusters, setSelectedClusters] = useState(null)
  const [showBulkDeleteDialog, setShowBulkDeleteDialog] = useState(false)
  const [showForceScaleMastersDialog, setShowForceScaleMastersDialog] = useState(false)
  const [showForceScaleWorkersDialog, setShowForceScaleWorkersDialog] = useState(false)

  const columns: GridViewColumn<SelectorModel>[] = [
    {
      key: 'uuid',
      label: 'UUID',
      CellComponent: UUIDCell,
      display: false,
    } as GridViewColumn<SelectorModel, 'uuid'>,
    {
      key: 'name',
      label: 'Name',
      width: 'medium',
      CellComponent: ClusterNameCell,
      memoizeCell: false,
    },
    {
      key: 'status',
      label: 'Cluster Health',
      width: 120,
      accessor: (cluster) => cluster,
      CellComponent: createGridStatusCell({
        dataFn: (cluster: QbertAndCapiCombinedClusterSelector): StatusCellModel => {
          return getManagedClusterHealthStatus(cluster)
        },
        iconDataFn: getManagedClusterAddonsStatus,
      }),
      display: isAdmin(),
    },
    {
      key: 'infrastructureType',
      label: 'Infrastructure Type',
      CellComponent: InfrastructureTypeCell,
    },
    {
      key: 'clusterType',
      label: 'Management API',
      CellComponent: ClusterTypeCell,
    },
    {
      key: 'version',
      label: 'K8s Version',
      width: 200,
      CellComponent: K8sVersionCell,
    },
    {
      key: 'k8sNodeStatus',
      accessor: (cluster) =>
        cluster.clusterType === ClusterTypes.Normal
          ? cluster.nodes
          : k8sNodes.filter((k8sNode) => k8sNode?.clusterId === cluster?.uuid),
      label: 'K8s Node Status',
      CellComponent: createGridStatusCell({
        dataFn: getClusterStatus,
      }),
      display: isAdmin(),
    },
    {
      key: 'usage',
      label: 'Resource Utilization',
      CellComponent: StatsCell,
    } as GridViewColumn<SelectorModel, 'usage'>,
    {
      key: 'creationTimestamp',
      label: 'Created',
      CellComponent: DateAndTime,
    },
    {
      key: 'links',
      label: 'Links',
      CellComponent: ClusterLinkCell,
      display: false,
    },
    {
      key: 'cidrRanges',
      label: 'CIDR Ranges',
      width: 'medium',
      CellComponent: createCidrRangesCell({
        dataFn: (cluster) => {
          return [
            { label: 'Containers', value: cluster?.containersCidr },
            { label: 'Services', value: cluster?.servicesCidr },
            { label: 'VPC CIDR', value: cluster?.vpcCidrBlock },
            { label: 'Pods', value: cluster?.podsCidrBlocks },
            { label: 'Services', value: cluster?.servicesCidrBlocks },
          ]
        },
      }),
      display: false,
    },
    {
      key: 'controlPlaneEndpoint',
      label: 'Control Plane Endpoint',
      display: false,
      formatFn: (value, cluster) =>
        isCapiCluster(cluster) ? cluster?.controlPlaneEndpoint : cluster?.externalDnsName,
    },
    {
      key: 'allowWorkloadsOnMaster',
      label: 'Master Workloads',
      display: false,
      CellComponent: getClusterBooleanCell({
        key: 'allowWorkloadsOnMaster',
        trueLabel: 'True',
        falseLabel: 'False',
      }),
    },
    {
      key: 'privileged',
      label: 'Privileged',
      display: false,
      CellComponent: getClusterBooleanCell({
        key: 'privileged',
        trueLabel: 'True',
        falseLabel: 'False',
      }),
    },
  ]

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      cond: (cluster) => {
        if (!isAdmin()) return false
        return isQbertCluster(cluster)
      },
      label: 'Edit',
      icon: 'edit',
      handleClick: (cluster) =>
        history.push(routes.cluster.managed.qbert.edit.path({ id: cluster?.uuid })),
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => {
        if (!isAdmin()) return false
        return isCapiCluster(cluster)
          ? canUpgradeCapiCluster([cluster])
          : canUpgradeCluster([cluster])
      },
      label: 'Upgrade',
      icon: 'level-up',
      handleClick: (cluster) => {
        const route = isCapiCluster(cluster)
          ? routes.cluster.managed.capi.upgrade.path({ id: cluster?.uuid })
          : routes.cluster.managed.qbert.upgrade.path({ id: cluster?.uuid })
        history.push(route)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => isAdmin() && isQbertCluster(cluster) && canScaleMasters([cluster]),
      icon: 'expand-alt',
      label: 'Scale Masters',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowForceScaleMastersDialog(true)
      },
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => isAdmin() && isQbertCluster(cluster) && canScaleWorkers([cluster]),
      icon: 'expand-alt',
      label: 'Scale Workers',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowForceScaleWorkersDialog(true)
      },
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => {
        if (!isAdmin()) return false
        return isCapiCluster(cluster)
          ? canDeleteCapiCluster([cluster])
          : canDeleteCluster([cluster])
      },
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: (clusters) => {
        if (!isAdmin()) return false
        return checkAllDeletable(clusters)
      },
      label: 'Delete',
      icon: 'trash',
      handleAction: (clusters) => {
        setSelectedClusters(clusters)
        setShowBulkDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  const infrastructureTypeOptions = useMemo(() => {
    return uniq(
      managedClusters.map((item) => {
        const infrastructureType = getInfrastructureType(item)
        return {
          label: infrastructureType,
          value: infrastructureType,
        }
      }),
    )
  }, [managedClusters])

  const clusterTypeOptions = useMemo(() => {
    return uniq(
      managedClusters.map((item) => ({
        label: formattedClusterTypes[item?.clusterType],
        value: item?.clusterType,
      })),
    )
  }, [managedClusters])

  const versionOptions = useMemo(() => {
    return uniq(
      managedClusters.map((item) => ({
        label: item?.version,
        value: item?.version,
      })),
    )
  }, [managedClusters])

  const dropdownFilters = useMemo(
    () => [
      {
        key: 'status',
        label: 'Cluster Health',
        FilterComponent: StringMultiDropdownFilter,
        filterComponentProps: {
          label: 'Cluster Health',
        },
        filterComponentOptionsPropName: 'dropdownOptions',
        getOptionsFn: () => {
          return clusterHealthOptions
        },
        equalityComparerFn: (item, value) => {
          // TODO: Clean this up
          const hasSome = value?.some((status) => {
            let healthy = null

            if (clusterIsBeingDeleted(item)) return false

            if (isQbertCluster(item)) {
              healthy = qbertClusterIsHealthy(item)
            } else {
              healthy = capiClusterIsHealthy(item)
            }
            return status === 'healthy' ? healthy : !healthy
          })
          return hasSome
        },
      },
      {
        key: 'infrastructureType',
        label: 'Infrastructure Type',
        FilterComponent: StringMultiDropdownFilter,
        filterComponentProps: {
          label: 'Infrastructure Type',
        },
        filterComponentOptionsPropName: 'dropdownOptions',
        getOptionsFn: () => {
          return infrastructureTypeOptions
        },
        equalityComparerFn: (item, value) => {
          const hasSome = value?.some((infrastructureType) => {
            const itemInfrastructureType = getInfrastructureType(item)
            return itemInfrastructureType === infrastructureType
          })
          return hasSome
        },
      },
      {
        key: 'clusterType',
        label: 'Management API',
        FilterComponent: StringMultiDropdownFilter,
        filterComponentProps: {
          label: 'Management API',
        },
        filterComponentOptionsPropName: 'dropdownOptions',
        getOptionsFn: () => {
          return clusterTypeOptions
        },
        equalityComparerFn: (item, value) => {
          const hasSome = value?.some((clusterType) => {
            return item?.clusterType === clusterType
          })
          return hasSome
        },
      },
      {
        key: 'version',
        label: 'K8s Version',
        FilterComponent: StringMultiDropdownFilter,
        filterComponentProps: {
          label: 'K8s Version',
        },
        filterComponentOptionsPropName: 'dropdownOptions',
        getOptionsFn: () => {
          return versionOptions
        },
        equalityComparerFn: (item, value) => {
          const hasSome = value?.some((version) => {
            return item?.version === version
          })
          return hasSome
        },
      },
    ],
    [clusterHealthOptions, infrastructureTypeOptions, clusterTypeOptions, versionOptions],
  )

  const DeleteDialog = useMemo(
    () => (isCapiCluster(selectedCluster) ? CapiClusterDeleteDialog : ClusterDeleteDialog),
    [selectedCluster],
  )

  return (
    <>
      <DocumentMeta title="Managed Clusters" />
      <PollingData
        hidden
        loading={loading}
        onReload={handleReload}
        refreshDuration={oneSecond * 30}
      />
      {showBulkDeleteDialog && (
        <BulkClusterDeleteDialog
          onClose={() => setShowBulkDeleteDialog(false)}
          rows={selectedClusters}
          onSuccess={() => setShowBulkDeleteDialog(false)}
        />
      )}
      {showDeleteDialog && (
        <DeleteDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedCluster]}
          onSuccess={() => setShowDeleteDialog(false)}
        />
      )}
      {showForceScaleMastersDialog && (
        <ForceScaleClusterDialog
          rows={[selectedCluster]}
          onClose={() => setShowForceScaleMastersDialog(false)}
          dialogRoute={() =>
            routes.cluster.managed.qbert.scaleMasters.path({ id: selectedCluster?.uuid })
          }
          canForceScale={both(isAdmin, canForceScaleMasters)}
        />
      )}
      {showForceScaleWorkersDialog && (
        <ForceScaleClusterDialog
          rows={[selectedCluster]}
          onClose={() => setShowForceScaleWorkersDialog(false)}
          dialogRoute={() =>
            routes.cluster.managed.qbert.scaleWorkers.path({ id: selectedCluster?.uuid })
          }
          canForceScale={both(isAdmin, canForceScaleWorkers)}
        />
      )}
      <UpgradeClusterPage />
      <ScaleMastersPage />
      <ScaleWorkersPage />
      <ListContainer<ModelDataKey, SelectorModel>
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage="Loading clusters..."
        onRefresh={handleReload}
        data={managedClusters}
        columns={columns}
        addUrl={routes.cluster.add.root.path()}
        addText="Create Cluster"
        // AddButtonComponent={AddClusterButton}
        getParamsUpdater={getParamsUpdater}
        label="Managed Clusters"
        showItemsCountInLabel
        rowMenuItems={rowMenuItems}
        maxRowMenuHeight={150}
        batchActions={batchActions}
        dropdownFilters={dropdownFilters}
        multiSelection
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
